<template>
  <div class="sideBar px-3 box-shadow bg-white">
    <button @click="resetFilter" class="btn primary-button text-white py-1">
      Clear all filters
    </button>
    <h6 class="my-3 secondaryColor border-bottom pb-3">Filters</h6>
 
  <div
      class="py-3 border-bottom"
    >
      <p class="f-16 mb-2">Select Range</p>
      <date-range-picker
        class="bg-light w-100"
        ref="picker"
        :opens="'right'"
        :locale-data="{ format: 'mm/dd/yyyy' }"
        :singleDatePicker="false"
        :timePicker="false"
        :timePicker24Hour="false"
        :showWeekNumbers="true"
        :showDropdowns="true"
        :autoApply="false"
        v-model="date_range"
      >
        <template v-slot:input="picker" style="min-width: 350px">
          {{ picker.startDate | date }} - {{ picker.endDate | date }}
        </template>
      </date-range-picker>
    </div>
   
    <div id="faqs-accordion" class="custom-accordion mt-2">
      <template>
        <div class="card shadow-none border-bottom pb-2 py-3">
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-gen-ques-empl
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-gen-ques-empl"
          >
            <!-- {{sideBarData.employee}} -->
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Employees</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>

          <b-collapse
            id="faqs-gen-ques-empl"
            visible
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <div class="">
              <vue-multiselect
                placeholder="Select an Employee"
                v-model="sideBarData.employee"
                :load-more="loadMoreEmployees"
                :loading="employees.loading"
                :options="employees.data"
                @on-open="fetchAllMembers"
                @on-reaching-end="fetchEmployeesOnReachingEnd"
                :id="'employees'"
                label="name" 
                internal-search
                showNoResult
              >
              </vue-multiselect>
            </div>
          </b-collapse>
        </div>
      </template>

      <!-- location filter -->
      <div
        class="card shadow-none border-bottom pb-2"
      >
        <a
          href="javascript: void(0);"
          v-b-toggle.faqs-gen-ques-loc
          class="text-dark"
          data-toggle="collapse"
          aria-expanded="true"
          aria-controls="faqs-gen-ques-loc"
        >
          <div class="border-0">
            <div class="media align-items-center">
              <div class="media-body overflow-hidden">
                <h5 class="font-size-14 fw-medium mb-1 fw-bold">Location</h5>
              </div>
              <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
            </div>
          </div>
        </a>

        <b-collapse
          id="faqs-gen-ques-loc"
          visible
          accordion="faqs-accordion"
          data-parent="#faqs-accordion"
        >
          <div class="y_scroll">
            <div>
              <div>
                <select
                  v-model="sideBarData.location"
                  class="form-select mt-2 bg-light border-0 shadow-none"
                >
                  <option value="showAll">Show All</option>
                  <option
                    v-for="(location, index) in locations"
                    :value="location"
                    :key="index"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import VueMultiselect from "@/components/general/vue-multiselect";
import _ from "lodash";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

const filter_Prototype = {
  location: null,
//   area: {},
  employee: {},
//   role: {},
};
export default {
  components: {
    VueMultiselect,
    DateRangePicker,
  },

  data() {
    return {
      size: 3,
    //   areas: {
    //     data: [],
    //     selected: [],
    //     VueMultiselectOpened: false,
    //     loading: false,
    //     nextPageUrl: null,
    //   },
      employees: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
    //   roles: {
    //     data: [],
    //     selected: [],
    //     VueMultiselectOpened: false,
    //     loading: false,
    //     nextPageUrl: null,
    //   },
      params: {},
      locations: [],
      location: "showAll",

      shift_names: [],
      date_range: {
        startDate: null,
        endDate: null,
      },
      sideBarData: {
        ...filter_Prototype,
      },
    };
  },
  computed: {
    // loadMoreAreas() {
    //   return !!this.areas.nextPageUrl;
    // },
    loadMoreEmployees() {
      return !!this.employees.nextPageUrl;
    },
  },
  watch: {
    sideBarData: {
      handler: function (newData) {
        this.$emit("filterData", newData);
        this.vacantEmployeeName = null;
      },
      deep: true,
    },

    "date_range.endDate": {
      handler: function (new_data, old_data) {
        if (new_data) {
          this.$emit("filterData", {
            startDate: this.formatDateYMD(this.date_range.startDate),
            endDate: this.formatDateYMD(this.date_range.endDate),
          });
        }
      },
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },

  methods: {
    resetFilter() {
      this.sideBarData = {
        ...filter_Prototype,
      };
      this.date_range = {
        startDate: null,
        endDate: null,
      };
      this.$emit("filterData", { clearFilters: true });
    },
    fetchAreas() {
      let url = "/restaurant/area/role/index";
      if (this.areas.nextPageUrl) {
        url = this.areas.nextPageUrl;
      }
      this.$axios
        .get(url, { params: this.params })
        .then((response) => {
          let result = response.data.payload.paginated;
          if (url == "/restaurant/area/role/index") {
            this.areas.data = [];
            this.roles.data = [];
          }
          this.areas.data.push(...result.data);
          //add roles
          if(this.isTeamMember()){ //this will get only employee roles in filter
            this.roles.data.push(...this.currentUser.employee.area_roles);
          }else{
            result.data.forEach((element) => {
              this.roles.data.push(...element.area_role);
            });
          }
          this.areas.nextPageUrl = result.next_page_url;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    fetchAllMembers() {
      let url = "/employee/team-member/all";
      if (this.employees.nextPageUrl) {
        url = this.employees.nextPageUrl;
      }
      this.$axios
        .get(url)
        .then((response) => {
          let result = response.data.payload;
          const mapped = result.data.map(d => ({name:d.user.name, id:d.id}));
          if (url == "/employee/team-member/all") this.employees.data = [];
          this.employees.data.push(...mapped);
          this.employees.nextPageUrl = result.next_page_url;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    resetData() {
      this.areas.data = [];
      this.areas.nextPageUrl = null;
    },

    fetchAreasOnReachingEnd() {
      this.fetchAreas();
    },
    fetchEmployeesOnReachingEnd() {
      this.fetchAllMembers();
    },

    fetchLocations() {
      this.$axios
        .get("/restaurant/location/index", { params: { paginated: false } })
        .then((response) => {
          this.locations = response.data.payload;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    onDayClick(day) {
      let selectDate = this.customFormatDate(day.id, "YYYY-MM-DD");
      this.$emit("filterData", {
        startDate: selectDate,
        endDate: selectDate,
      });
    },
    searchByName() {
      this.$emit("searchVacantEmployee" , this.vacantEmployeeName)
      // this.sideBarData.vacantEmployeeName = this.vacantEmployeeName;
    },

    getShiftNames() {
      this.$axios.get('shift/name/index' , {params: {pagination : 0}}).then((response) => {
          let data = response.data.payload.paginated;
          this.shift_names = data;
      }).catch((error) => {
      this.handleErrorResponse(error.response, "error");
      });
    },
  },
  mounted() {
    this.fetchLocations();
    this.getShiftNames();
  },
};
</script>