<!-- @format -->

<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import moment from "moment";
import sidebar from "./components/sidebar.vue";
import AlertWidget from "@/components/general/AlertWidget";
import pagination from "@/views/pages/admin/calendar/reuseableComponents/pagination.vue";


/**
 * Add-product component
 */
export default {
  page: {
    title: "Employee Salary Invoice",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    sidebar,
    AlertWidget,
    pagination
  },

  data() {
    return {
      title: "Employee Salary Invoice",
      table_data: {
        from: null,
        to: null,
        length : 10,
      },
      employees: [],
      isClick: false,
      paginationData: null,

    };
  },
  watch: {},
  methods: {
    fetchEmployeesPayroll(url) {
      url = url ?? "employee/team-member/payroll/index";
      this.$axios
        .get(url, { params: this.table_data })
        .then((response) => {
          let data = response.data.payload;
          this.paginationData = data;
          this.employees = data.data;
        });
    },
    arrayToString(locations) {
      let name_array = locations.map((loc) => {
        return loc.name;
      });
      let string = name_array.join(", ").toString();
      return string;
    },

    listenFromSideBar(newData) {
      if (newData?.location) {
        if (newData?.location == "showAll") {
          this.table_data.location_id = null;
        } else {
          this.table_data.location_id = newData.location.id;
        }
      }

      //   if (newData?.area) {
      //     this.table_data.area_id = newData?.area?.id;
      //   }

      if (newData?.employee) {
        this.table_data.employee_id = newData?.employee?.id;
      }

      if (newData?.endDate) {
        this.table_data.from = newData.startDate;
        this.table_data.to = newData.endDate;
      }

      //   if (newData?.role) {
      //     this.table_data.area_role_id = newData?.role?.id;
      //   }

      if (newData?.clearFilters) {
        this.table_data = {
          length : 10
        };
        this.table_data.from = this.formatDateYMD(
          moment().startOf("month").toDate()
        );
        this.table_data.to = this.formatDateYMD(
          moment().endOf("month").toDate()
        );
      }

      if (newData != null) {
        this.fetchEmployeesPayroll();
      }
    },
  },
  mounted() {
    this.table_data.from = this.formatDateYMD(
      moment().startOf("month").toDate()
    );
    this.table_data.to = this.formatDateYMD(moment().endOf("month").toDate());
    this.fetchEmployeesPayroll();
  },
};
</script>

<template>
  <Layout>
    <!-- <div class="row"> -->
    <div class="row">
      <div
        class="col-xl-2 ps-0 position-relative transition_class"
        :class="{ fullWidth: isClick }"
      >
        <sidebar ref="sideBar" @filterData="listenFromSideBar" />
        <i
          class="fas position-absolute top-0 end-0 px-4 f-16 primaryColor pointer"
          @click="isClick = !isClick"
          :class="isClick == false ? 'fa-times' : 'fa-bars'"
        >
        </i>
      </div>
      <div class="col-xl-10" :class="{ 'col-xl-12': isClick }">
        <div class="row">
          <div class="col-md-6">
            <PageHeader :title="title" />
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-end">
              <div class="dropdown-btn">
                <!-- <button class="btn text-white" >Download Invoice</button> -->
              </div>
            </div>
          </div>
        </div>
        

        <div class="card">
          <div class="card-body">
            <div class="row col-12">
              <div class="col-md-12 mb-0">
                <div class="my-3 table-responsive">
                  <table class="table table-bordered text-nowrap">
                    <thead>
                      <tr>
                        <th class="text-capitalize">Employee</th>
                        <th class="text-capitalize text-center">Location</th>
                        <th class="text-capitalize text-center">Start Date</th>
                        <th class="text-capitalize text-center">End Date</th>
                        <th class="text-capitalize text-center">
                          Rate Per Hour
                        </th>
                        <th class="text-capitalize text-center">
                          Total Working Hours
                        </th>
                        <th class="text-capitalize text-center">
                          Bonus Amount
                        </th>
                        <th class="text-capitalize text-center">
                          Total Salary
                        </th>
                        <!-- <th class="text-capitalize text-center">Payment Status</th> -->
                        <!-- <th class="text-capitalize text-center">actions</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(employee, index) in employees" :key="index">
                        <td class="align-middle text-center">
                          <router-link :to="{ name: 'single-employee', params: { employee_id: employee.id }}">
                          <div class="d-flex">
                            <span>
                              <img
                                :src="
                                  getSafe(()=>  employee.user.avatar == null
                                    ? employee.user.avatar_path
                                    : assets_url + employee.user.avatar)
                                "
                                class="avatar-xs rounded-circle me-2"
                              />
                            </span>
                            <span
                              class="align-self-center fw-bold text-capitalize"
                              >{{ getSafe(() => employee.user.name) }}</span
                            >
                          </div>
                          </router-link>
                        </td>
                        <td class="align-middle text-center text-capitalize">
                          {{ getSafe(() => arrayToString(employee.locations)) }}
                        </td>
                        <td class="align-middle text-center">
                          {{ getSafe( () => DateWithMonthName(table_data.from , 'DD-MMM-YYYY') ) }}
                        </td>
                        <td class="align-middle text-center">
                          {{ getSafe( () => DateWithMonthName(table_data.to ,  'DD-MMM-YYYY') ) }}
                        </td>
                        <td class="align-middle text-center">
                          {{
                            getSafe(() => employee.payment)
                              ? "$" + employee.payment + " base salary per-hour"
                              : "Pay Role per-Hour"
                          }}
                        </td>
                        <td class="align-middle text-center">
                          <span class="text-danger">
                            {{ employee.total_hours }} Hrs</span
                          >
                        </td>
                        <td class="align-middle text-center">
                          <span
                            :class="{ secondaryColor: employee.salary > 0 }"
                            >{{
                              getSafe(() => employee.total_bonus)
                                ? "$" + getSafe(() => employee.total_bonus)
                                : "$0"
                            }}</span
                          >
                        </td>
                        <td class="align-middle text-center">
                          <span>{{
                            getSafe(() => employee.salary)
                              ? "$" + getSafe(() => employee.salary)
                              : "$0"
                          }}</span>
                        </td>
                        <!-- <td class="align-middle text-center">
								<span>{{ employee.payment_status }}</span>
								</td> -->
                        <!-- <td class="align-middle text-center"> 
								<b-dropdown
									:id="`dropdown${index}`"
									html="<i class='fas fa-ellipsis-h'></i>"
									class="m-md-2 btn-light x-dropdown"
									right
									@click="showDropdown = true"
								>
									<b-dropdown-item>First Action</b-dropdown-item>
									<b-dropdown-item>Second Action</b-dropdown-item>
									<b-dropdown-item>Third Action</b-dropdown-item>
								</b-dropdown> 
								</td>-->
                      </tr>
                      <tr v-if="employees.length == 0">
                        <td colspan="9">
                          <AlertWidget
                            class="mt-4"
                            text="No Record Found"
                          ></AlertWidget>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <pagination
                :paginationProp="paginationData"
                @next="fetchEmployeesPayroll"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
